import React from 'react';
import MarketOrderForm from './MarketOrderForm';

const ItemMarketForm = ({ mhash, quantityOwned}) => (
  <div className="middle-column">
    <MarketOrderForm mhash={mhash} quantityOwned={quantityOwned} />
  </div>
);

export default ItemMarketForm;
