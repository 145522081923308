import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import FilterMenu from './FilterMenu';
import Homebar from './Homebar';
import TradeModal from './TradeModal';
import './InventoryPage.css';
import "./ItemCard.css";
import { Modal, Typography, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox } from '@mui/material';
import FormControlLabel from "@mui/material/FormControlLabel";

const InventoryPage = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [activeTrades, setActiveTrades] = useState([]);                             // Store active trades
  const [isTradeModalOpen, setIsTradeModalOpen] = useState(false);                  // Is trade modal open
  const [sortedItems, setSortedItems] = useState(items);                            // State to store sorted items
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });  // Sorting configuration


  const handleSelectItem = (item) => {
    setSelectedItems((prevSelectedItems) => {
      const existingItemIndex = prevSelectedItems.findIndex((i) => i.mhash === item.mhash);

      if (existingItemIndex !== -1) {
        // If item exists, remove it
        return prevSelectedItems.filter((i) => i.mhash !== item.mhash);
      } else {
        // If item doesn't exist, add it with default or preserved state
        const existingItem = prevSelectedItems.find((i) => i.mhash === item.mhash);
        const withdrawQuantity = existingItem ? existingItem.withdrawQuantity : 1;
        return [...prevSelectedItems, { ...item, withdrawQuantity }];
      }
    });
  };

  useEffect(() => {
    const fetchItems = async () => {
      try {
        // Step 1: Fetch inventory items
        let { data: inventory, error: inventoryError } = await supabase.rpc('get_inventory', {});
  
        if (inventoryError) {
          console.log("Error trying to fetch inventory", inventoryError);
          return;
        }
  
        // console.log("Fetched inventory:", inventory);
  
        if (inventory.length === 0) {
          setItems([]); // No inventory items
          return;
        }
  
        // Extract all mhash values from inventory
        const mhashList = inventory.map(item => item.mhash);
  
        // Step 2: Query Item table for additional details
        let { data: itemDetails, error: itemError } = await supabase
          .from('Item')
          .select('*') // Select all item details, modify if needed
          .in('mhash', mhashList); // Match against inventory mhash values
  
        if (itemError) {
          console.error("Error fetching item details:", itemError);
          return;
        }
  
        // console.log("Fetched item details:", itemDetails);
  
        // Step 3: Merge inventory data with item details
        const enrichedItems = inventory.map(invItem => ({
          ...invItem,
          details: itemDetails.find(item => item.mhash === invItem.mhash) || {}
        }));
  
        setItems(enrichedItems);
      } catch (error) {
        console.error('Error fetching items:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchItems(); // Fetch inventory and item details on mount

    // Fetch active trades on initial page load
    const fetchActiveTrades = async () => {
      try {
        const { data, error } = await supabase
          .from('ItemTrade')
          .select('*')
          .eq('active', true); // Fetch active trades
        if (error) {
          console.error('Error fetching active trades:', error);
        } else {
          // console.log('Fetched active trades:', data);
          setActiveTrades(data);
          // console.log('Fetched length:', data.length);
          if (data.length > 0) {
            setIsTradeModalOpen(true); 
          }
        }
      } catch (error) {
        console.error('Error fetching active trades:', error);
      }
    };

    fetchActiveTrades(); // Fetch active trades on page load

    // Subscribe to real-time trade updates
    const subscription = supabase
      .channel('public:ItemTrade')
      .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'ItemTrade' }, (payload) => {
        console.log('New trade detected:', payload.new);

        if (payload.new.active) {
          setActiveTrades((prevTrades) => [...prevTrades, payload.new]); // Add new trade
          setIsTradeModalOpen(true); // Open the modal
        }
      })
      .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'ItemTrade' }, (payload) => {
        console.log('Trade updated:', payload.new);
        setIsTradeModalOpen(true); // Open the modal

        setActiveTrades((prevTrades) =>
          prevTrades.map((trade) =>
            trade.id === payload.new.id ? payload.new : trade
          )
        );
      })
      .subscribe();

    return () => {
      supabase.removeChannel(subscription); // Clean up the subscription on unmount
    };
  }, []); // Empty dependency array to run once when the page loads

  useEffect(() => {
    if (activeTrades.length > 0) {
      setIsTradeModalOpen(true); // Open the modal if there are active trades
    }

  }, [activeTrades, isTradeModalOpen]);

   // Function to sort items based on a column
   const handleSort = (key) => {
    let direction = 'desc';
    if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = 'asc';
    }

    setSortConfig({ key, direction });

    const sortedArray = [...items].sort((a, b) => {
      if (key === 'value') {
        // Calculate value: last_price * quantity, ensuring they are numbers
        const valueA = (a.details.last_price || 0) * (a.quantity || 0);
        const valueB = (b.details.last_price || 0) * (b.quantity || 0);
        return direction === 'asc' ? valueA - valueB : valueB - valueA;
      }

      if (key === 'price') {
        return direction === 'asc'
          ? (a.details.last_price || 0) - (b.details.last_price || 0)
          : (b.details.last_price || 0) - (a.details.last_price || 0);
      }

      if (key === 'quantity') {
        return direction === 'asc' ? (a.quantity || 0) - (b.quantity || 0) : (b.quantity || 0) - (a.quantity || 0);
      }

      // Default sorting by item name
      return direction === 'asc'
        ? a.mhash.localeCompare(b.mhash)
        : b.mhash.localeCompare(a.mhash);
    });

    setSortedItems(sortedArray);
  };

  useEffect(() => {
    setSortedItems(items); // Initialize sortedItems when items change
  }, [items]);
  
 return (
  <div className="inventory-page">
    <Homebar />
    <div className="content-container" style={{ display: 'flex', minWidth: '85%', height: '100%' }}>
      <div className="main-content" style={{ width: "100%", height: "100%" }}>
        <TradeModal trades={activeTrades} isOpen={isTradeModalOpen} onClose={() => setIsTradeModalOpen(false)} />

          {/* Banner Section */}
          <Box
            sx={{
              width: "100%",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#303030",
            }}
          >
            <Typography variant="h4" sx={{ color: "rgb(39, 174, 96)" }}>
              Inventory
            </Typography>
          </Box>

        {/* Inventory Table */}
        <Box sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <div className="inventory-table">
            {/* Table headers */}
            <div className="inventory-table-header">
              <div className="inventory-table-cell" onClick={() => handleSort('name')}>Item</div>
              <div className="inventory-table-cell" onClick={() => handleSort('quantity')}>Quantity</div>
              <div className="inventory-table-cell" onClick={() => handleSort('price')}>Price</div>
              <div className="inventory-table-cell" onClick={() => handleSort('value')}>Value</div>
            </div>

            {/* Table content */}
            {sortedItems.map((item, index) => (
                

                <div
                  key={index}
                  className="inventory-card"
                  onClick={(e) => {
                    // Check if the target of the click is the checkbox; if so, prevent redirect
                    if (e.target.type !== 'checkbox') {
                      window.location.href = `/item/${item.mhash}`; // Redirect to item page based on mhash
                    }
                  }}
                >

                {/* Checkbox for selecting the item */}
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedItems.some((selectedItem) => selectedItem.mhash === item.mhash)}
                        onChange={(e) => {
                          e.stopPropagation(); // Prevents click from triggering redirect
                          handleSelectItem(item);
                        }}
                        sx={{ transform: "scale(1.25)" }} // Makes checkbox larger
                      />
                    }
                    label="" // Empty label to keep only the checkbox
                  />

                  {/* Item image and name */}
                  <img
                    src={`https://community.fastly.steamstatic.com/economy/image/${item.details.image_link}`}
                    alt={item.mhash}
                    className="item-icon"
                  />
                  <div className="inventory-card-info">
                    <h3 className="inventory-item-name">{item.mhash}</h3>
                    <p className="inventory-item-quantity">{item.quantity}x</p>
                    <span className="inventory-item-price">
                      ${(item.details.last_price || 0) / 100}
                    </span>
                    <span className="inventory-item-value">
                      ${(item.details.last_price * item.quantity || 0) / 100}
                    </span>


                  </div>
                </div>
              ))}
          </div>
        </Box>
        </div>
      </div>
      <div className="information-panel">
        {/* Information Panel for Withdrawals */}
        <Box
          sx={{
            maxWidth: "15%",
            backgroundColor: "#505050",
            color: "white",
            padding: 2,
            position: "fixed",
            right: 0,
            top: 0,
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6">Withdraw Items</Typography>

          {selectedItems.length === 0 ? (
            <Typography variant="body2" sx={{ marginTop: 2 }}>
              Select items to send to your Steam account
            </Typography>
          ) : (
            <Box sx={{ mt: 2 }}>
              {selectedItems.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#404040",
                    padding: "10px",
                    borderRadius: "5px",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="body2" sx={{ flex: 1 }}>
                    {item.mhash}
                  </Typography>
                  <input
                    type="number"
                    min="1"
                    max={item.quantity}
                    value={item.withdrawQuantity || 1}
                    onChange={(e) => {
                      const newQuantity = parseInt(e.target.value, 10);
                      setSelectedItems((prevSelectedItems) =>
                        prevSelectedItems.map((i) =>
                          i.mhash === item.mhash ? { ...i, withdrawQuantity: newQuantity } : i
                        )
                      );
                    }}
                    style={{
                      width: "50px",
                      textAlign: "center",
                      marginRight: "10px",
                      borderRadius: "5px",
                      border: "none",
                      padding: "5px",
                    }}
                  />
                  <Button
                    onClick={() => {
                      setSelectedItems((prevSelectedItems) =>
                        prevSelectedItems.filter((i) => i.mhash !== item.mhash)
                      );
                    }}
                    sx={{ color: "red", minWidth: "30px" }}
                  >
                    🗑️
                  </Button>
                </Box>
              ))}

              <Button
                onClick={async () => {
                  const commodityItems = selectedItems.map((item) => item.mhash);
                  const commodityQuantities = selectedItems.map((item) => item.withdrawQuantity || 1);

                  try {
                    const { data, error } = await supabase.rpc("create_withdrawal", {
                      p_commodity_item: commodityItems,
                      p_commodity_quantity: commodityQuantities,
                    });

                    if (error) {
                      console.error("Error creating withdrawal:", error);
                    } else {
                      alert(`Successfully withdrew ${commodityQuantities.reduce((acc, qty) => acc + qty, 0)} items`);
                      setSelectedItems([]);
                      setIsTradeModalOpen(true);
                    }
                  } catch (error) {
                    console.error("Error creating withdrawal:", error);
                  }
                }}
                sx={{
                  marginTop: "20px",
                  backgroundColor: "#28a745",
                  color: "white",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "#218838",
                  },
                }}
              >
                Withdraw {selectedItems.reduce((acc, item) => acc + (item.withdrawQuantity || 1), 0)} items
              </Button>
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

export default InventoryPage;
