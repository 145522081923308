import React, { useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";

// Stripe setup
const stripePromise = loadStripe("pk_test_51QhIlaFSX19rVOuyqQ0iq7V2fs1PWSY96ddAXNOv576jVROhDtxN2FFnJlNwnJrMi5kYetboGnbAOFc5kFMy9Kme001MZU1eDC");

const CheckoutForm = ({ email }) => { // Accept user as a prop
  const fetchClientSecret = useCallback(() => {
    if (!email) {
      console.error("User email is required for checkout.");
      return Promise.reject("User email is missing.");
    }

    const price = 2000;

    return fetch(`http://localhost:3005/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, price }),
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret)
      .catch((error) => {
        console.error("Error fetching client secret:", error);
      });
  }, [email]);

  const options = { fetchClientSecret };

  return (
    <div id="checkout" style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}>
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default CheckoutForm;
