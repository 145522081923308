import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();

      if (error) {
        console.error("❌ Auth error:", error.message);
        return;
      }

      console.log("✅ OAuth Success:", session);
      navigate("/market"); // Redirect user after login
    };

    checkSession();
  }, [navigate]);

  return <p>Processing authentication...</p>;
};

export default AuthCallback;
