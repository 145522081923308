import React from 'react';

const ItemHeader = ({ item }) => (
  // console.log("header item:",item),
  <div style={{ color: '#ffffff', display: 'flex', flexWrap: 'nowrap', gap: '10px', whiteSpace: 'nowrap', alignItems: 'center' }}>
    <h1>{item.mhash}</h1>
    <div style={{ color: '#b7b7b7' }}>
      <p>({item.category}) </p>
    </div>
  </div>
);

export default ItemHeader;
