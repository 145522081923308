import React from 'react';
import { Container, Typography, Paper, Box, Button } from '@mui/material';
import Homebar from './Homebar';
import Banner from '../components/Banner';
import { width } from '@mui/system';
import { useNavigate } from 'react-router-dom'; 
import FAQSection from './FAQSection';
import Footer from './Footer';

const HomePage = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="homepage">
      <Homebar />
      <div className="main-content" style={{ backgroundColor: "#545454", height:'100%'}}>
        <Banner />
        <FAQSection />
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
