import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import { FaWallet } from 'react-icons/fa';
import { Button, TextField, Box } from '@mui/material';
import { supabase } from '../supabaseClient';
import './Homebar.css';

const Homebar = ({ onBalanceUpdate, onUserEmail }) => {
  const navigate = useNavigate();
  const { session } = useUserContext();
  const { user } = session || {}; 
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const fetchUserBalance = async () => {
      if (user) {
        const { data: userData, error } = await supabase
          .from('User')
          .select('balance')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching user balance:', error);
        } else {
          setBalance(userData.balance);
          if (onBalanceUpdate != null) {
            onBalanceUpdate(userData.balance);
          }
        }
      }
    };

    fetchUserBalance();

    if (user) {
      const channel = supabase
        .channel('realtime:User')
        .on(
          'postgres_changes',
          {
            event: 'UPDATE',
            schema: 'public',
            table: 'User',
            filter: `id=eq.${user.id}`,
          },
          (payload) => {
            if (payload.new?.balance !== undefined) {
              setBalance(payload.new.balance);
              onBalanceUpdate(balance);
            }
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [user]);

  // Send user email up when available
  useEffect(() => {
    if (user?.email) {
      if (onUserEmail != null) {
        onUserEmail(user.email);
      }
    }
  }, [user?.email, onUserEmail]);

  return (
    <div className="homebar">
      <div className="left-section">
        <div className="logo" onClick={() => navigate('/')}>Case-Exchange</div>
        <nav className="navigation">
          <ul>
            <li onClick={() => navigate('/market')}>Market</li>
            <li onClick={() => navigate('/inventory')}>Inventory</li>
          </ul>
        </nav>
      </div>

      <div className="auth-buttons">
        {user ? (
          <>
            <div className="balance" onClick={() => navigate('/wallet')} style={{ cursor: 'pointer' }}>
              <FaWallet className="wallet-icon" />
              <span>${(balance / 100).toFixed(2)}</span>
            </div>
            <div className="welcome-dropdown">
              <span className="welcome-message">Welcome, {user.email.slice(0, 2)}***@***.com</span>
              <div className="dropdown">
                <span 
                  className="dropdown-item" 
                  onClick={async () => {
                    await supabase.auth.signOut();
                  }}
                >
                  Sign Out
                </span>
                <span 
                  className="dropdown-item" 
                  onClick={() => navigate('/settings')}
                >
                  Settings
                </span>
              </div>
            </div>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/signin')}
          >
            Sign In
          </Button>
        )}
      </div>
    </div>
  );
};

export default Homebar;
