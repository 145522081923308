import React from 'react';
import { FaFlagUsa } from 'react-icons/fa'; // Import American flag icon
import { Box, Typography, Container } from '@mui/material'; // Import MUI components
import './Banner.css';

const Banner = () => {
  return (
    <Box
      className="banner"
      sx={{
        backgroundColor: '#333', // Dark background
        color: 'white', // White text
        padding: '20px 0', // Top and bottom padding
        textAlign: 'center', // Center text
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
      }}
    >
      <Container>
        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
          Welcome to Case-Exchange. 
        </Typography>
        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
          0% Transaction Fees on all purchases and sales. No catch, just savings.
        </Typography>

        <Typography variant="h4" sx={{ marginTop: '10px', color: '#fffff' }}>
          The newest and most secure way to buy and sell CS2 cases and items.
        </Typography>
        <Typography variant="h6" sx={{ marginTop: '10px', color: '#ddd', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          An American Company
          <FaFlagUsa style={{ marginLeft: '10px', fontSize: '24px' }} />
        </Typography>
      </Container>
    </Box>
  );
};

export default Banner;
