import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box
} from '@mui/material';  // Changed from '@/components/ui/mui' to '@mui/material'

const TransactionList = ({transactions}) => {
  // const transactions = [
  //   { id: 0, description: 'Sample Deposit', amount: 0.0001, date: '2025-5-31' },
  //   { id: 0, description: 'Sample Deposit', amount: 0.0001, date: '2025-5-31' },
  //   { id: 0, description: 'Sample Deposit', amount: 0.0001, date: '2025-5-31' },
  // ];

  // If you need pagination, you can uncomment and use these:
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  // const [page, setPage] = useState(0);

  // useEffect with proper dependency if you need it:
  // useEffect(() => {
  //   fetchItems();
  // }, [page]); // Add any other dependencies you need

  return (
    <Box sx={{ height: '100%', width: '100%', bgcolor: '#303030' }}>
      <Box sx={{ p: 2.5 }}>
        <Typography 
          variant="h5" 
          sx={{ 
            mb: 2,
            color: '#27ae60',
            fontWeight: 600
          }}
        >
          Balance History
        </Typography>
        
        <TableContainer 
          component={Paper} 
          sx={{ 
            bgcolor: '#2c2c2c',
            boxShadow: 3
          }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: '#3a3a3a' }}>
                <TableCell sx={{ color: '#e0e0e0', fontWeight: 600 }}>Amount</TableCell>
                <TableCell sx={{ color: '#e0e0e0', fontWeight: 600 }}>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction) => (
                <TableRow 
                  key={transaction.id}
                  sx={{
                    bgcolor: '#3a3a3a', 
                    '&:hover': { 
                      bgcolor: '#3a3a3a',
                      transition: 'background-color 0.2s'
                    }
                  }}
                >
                  <TableCell 
                    sx={{ 
                      color: transaction.amount > 0 ? '#27ae60' : '#e74c3c',
                      fontWeight: 500
                    }}
                  >
                    {transaction.amount > 0 ? '+' : '-'} 
                    ${Math.abs(transaction.amount).toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ color: '#e0e0e0' }}>
                    {transaction.created_at ? new Date(transaction.created_at).toLocaleString() : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TransactionList;