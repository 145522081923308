import React, { useState, useEffect } from 'react';
import Homebar from './Homebar'; // Assuming Homebar is a component
import './WalletPage.css'; // Import CSS
import CheckoutForm from './CheckoutForm'; // Import CheckoutForm
import TransactionList from './TransactionList';
import { Button, Modal, Box } from '@mui/material';
import { supabase } from '../supabaseClient';

function WalletPage() {
  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState(0); // State to store balance
  const [userEmail, setUserEmail] = useState(""); // Store user email
  const [transactions, setTransactions] = useState([]);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  useEffect(() => {
    const fetchTransactions = async () => {
      
      const { data, error } = await supabase.rpc('get_user_deposits', { p_page: 1 });

      if (error) {
        console.error("Error fetching transactions via RPC:", error);
        setTransactions([]);
      } else {
        setTransactions(data || []);
      }
    };

    fetchTransactions(); // Call it once when component loads
    // console.log("Transactions fetched:", transactions); // Log the transactions
  }, [userEmail]); // Empty dependency array ensures it runs only once
  

  const handleBalanceUpdate = (newBalance) => {
    // console.log("Balance updated to:", newBalance);
    setBalance(newBalance);
  };

  return (
    <div className="wallet-page">
      <Homebar onBalanceUpdate={handleBalanceUpdate} onUserEmail={setUserEmail} />
      <div className="content-container">
        <div className="main-content">
          <h3>Balance: ${(balance/100).toFixed(2)}</h3>

          {/* Green Deposit Button */}
          <Button 
            variant="contained" 
            color="success" 
            onClick={handleOpen} 
            style={{ margin: '20px 0' }}
          >
            Deposit
          </Button>

          {/* Modal for Checkout Form */}
          <Modal open={open} onClose={handleClose}>
            <Box 
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                minWidth: '100px',
                width: '80%',
                maxWidth: '400px',
                minHeight: '150px',
                maxHeight: '90vh', // Prevent overflow
                height: 'auto',
                overflow: 'auto'
              }}
            >
              <CheckoutForm email={userEmail}/>
            </Box>
          </Modal>

          <TransactionList className="wallet-transaction-list" transactions={transactions}/>

        </div>
      </div>
    </div>
  );
}

export default WalletPage;
