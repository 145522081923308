import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Container, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Paper,
  Divider,
  Link,
  Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SecurityIcon from '@mui/icons-material/Security';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SellIcon from '@mui/icons-material/Sell';
import InventoryIcon from '@mui/icons-material/Inventory';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PublicIcon from '@mui/icons-material/Public';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HelpIcon from '@mui/icons-material/Help';
import GroupsIcon from '@mui/icons-material/Groups';

// Custom theme matching your site's color scheme
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#27ae60',
    },
    secondary: {
      main: '#1abc9c',
    },
    background: {
      default: '#303030',
      paper: '#545454',
    },
    text: {
      primary: '#ffffff',
      secondary: '#e0e0e0',
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#545454',
          '&:before': {
            display: 'none',
          },
          boxShadow: 'none',
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #27ae60',
          '&.Mui-expanded': {
            minHeight: '48px',
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: '12px 0',
          },
        },
      },
    },
  },
});

const FAQSection = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqs = [
    {
      id: 'faq1',
      icon: <PersonAddIcon color="primary" />,
      question: 'How can I get started?',
      answer: (
        <>
          To start, simply click the "Sign In" button at the top right corner of the page. Login with  Gmail to safely link your email to your account. This method is the most secure because it does not require you to enter your password or grant us access to your Steam account, unlike our competitors.
        </>
      ),
    },
  
    {
      id: 'faq2',
      icon: <ShoppingCartIcon color="primary" />,
      question: 'How to buy skins?',
      answer: 'Buying skins is simple: browse our marketplace, select your desired item, review the price and condition, and complete your purchase with a single click. Your new items will appear instantly in your inventory.'
    },
    {
      id: 'faq3',
      icon: <SellIcon color="primary" />,
      question: 'How to sell skins?',
      answer: 'To sell your CS2 items, navigate to the item in your inventory, click on it to view the item details page, and create a sell listing with your desired price. Our system will match you with buyers automatically.'
    },
    {
      id: 'faq4',
      icon: <InventoryIcon color="primary" />,
      question: 'How to deposit skins?',
      answer: 'We currently do not offer direct skin deposits. We\'re working on implementing this feature to provide you with more flexibility in the future.'
    },
    {
      id: 'faq5',
      icon: <InventoryIcon color="primary" />,
      question: 'How to withdraw skins?',
      answer: 'Withdrawing items is straightforward: visit your inventory, select the items you wish to withdraw, and confirm the trade. A secure trade offer will be sent directly to your linked Steam account within seconds.'
    },
    {
      id: 'faq6',
      icon: <SecurityIcon color="primary" />,
      question: 'Are my skins safe?',
      answer: 'Your security is our top priority. Case-Exchange implements OAuth technology, eliminating password vulnerabilities by linking directly to your email. All transactions are monitored 24/7 by our security team, ensuring your items remain protected.'
    },
    {
      id: 'faq7',
      icon: <PublicIcon color="primary" />,
      question: 'Do I have to be a US customer?',
      answer: 'No but you will be unable to deposit funds onto the site. Currently we require all deposits to be made from US-based debit cards. However, we are actively working on expanding our services to international customers in the near future.'
    },
    {
      id: 'faq8',
      icon: <CreditCardIcon color="primary" />,
      question: 'What payment methods are accepted?',
      answer: 'We accept all major US debit cards for seamless deposits. Our team is actively working to expand payment options in the near future to better serve our community.'
    },
    {
      id: 'faq9',
      icon: <AccountBalanceWalletIcon color="primary" />,
      question: 'Can I withdraw funds?',
      answer: 'Balance withdrawals are currently in development. We\'re finalizing our secure payment processing system to ensure fast and reliable cash-outs. Join our Discord community for updates on this upcoming feature.'
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ py: 5 }}>
        {/* FAQ Section */}
        <Paper 
          elevation={3} 
          sx={{ 
            backgroundColor: 'background.paper', 
            p: 4, 
            mb: 5,
            borderRadius: 2,
            position: 'relative',
            overflow: 'hidden',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '4px',
              background: 'linear-gradient(90deg, #27ae60, #1abc9c)',
            }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <HelpIcon sx={{ fontSize: 32, color: 'primary.main', mr: 2 }} />
            <Typography variant="h4" component="h2" fontWeight="bold">
              Frequently Asked Questions
            </Typography>
          </Box>
          
          <Divider sx={{ mb: 3 }} />
          
          {faqs.map((faq) => (
            <Accordion 
              key={faq.id}
              expanded={expanded === faq.id}
              onChange={handleChange(faq.id)}
              sx={{ mb: 1 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${faq.id}-content`}
                id={`${faq.id}-header`}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ mr: 2 }}>{faq.icon}</Box>
                  <Typography variant="h6" fontWeight="medium">{faq.question}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" sx={{ pl: 5 }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Paper>
        
        {/* About Us Section */}
        <Paper 
          elevation={3} 
          sx={{ 
            backgroundColor: 'background.paper', 
            p: 4,
            borderRadius: 2,
            position: 'relative',
            overflow: 'hidden',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '4px',
              background: 'linear-gradient(90deg, #27ae60, #1abc9c)',
            }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <GroupsIcon sx={{ fontSize: 32, color: 'primary.main', mr: 2 }} />
            <Typography variant="h4" component="h2" fontWeight="bold">
              About Us
            </Typography>
          </Box>
          
          <Divider sx={{ mb: 3 }} />
          
          <Typography variant="body1" paragraph>
            Case-Exchange is built by a dedicated team of developers who share a passion for gaming and trading. Our mission is to create the most secure and user-friendly CS2 item marketplace.
          </Typography>
          
          <Typography variant="body1" paragraph>
            What sets us apart is our unwavering commitment to customer service and exceptional user experience. Every feature on our platform is designed with our users in mind, ensuring a seamless trading process from start to finish.
          </Typography>
          
          <Typography variant="body1" paragraph>
            We're constantly evolving, with new features and improvements in development based on community feedback. Our roadmap includes expanded payment options, enhanced trading tools, and more features. If you think of anything we should add, please let us know at the below email address. 
          </Typography>
          
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button 
              variant="contained" 
              color="primary" 
              size="large"
              component={Link}
              href="mailto:info@case-exchange.com"
              sx={{ 
                borderRadius: 2,
                px: 4
              }}
            >
              Contact Us
            </Button>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default FAQSection;