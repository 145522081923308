import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Homepage from "./components/Homepage";
import MarketPage from "./components/MarketPage";
import WalletPage from "./components/WalletPage";
import InventoryPage from "./components/InventoryPage";
import ItemDetailPage from "./components/ItemDetailPage";
import SignInPage from "./components/SignInPage";
import SettingsPage from "./components/SettingsPage";
import AuthCallback from './components/AuthCallback';
import Return from "./components/Return"; // Import Return
import { UserProvider } from "./context/UserContext"; // Adjust the path as necessary

import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  return (
    <>
      <UserProvider>
        <Router>
          <div style={{width: '100%', height: '100%'}}>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/market" element={<MarketPage />} />
              <Route path="/wallet" element={<WalletPage />} />
              <Route path="/auth/callback" element={<AuthCallback />} />
              <Route path="/inventory" element={<InventoryPage />} />
              <Route path="/item/:mhash" element={<ItemDetailPage />} />
              <Route path="/signin" element={<SignInPage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/return" element={<Return />} /> {/* Stripe Return */}
            </Routes>
          </div>
        </Router>
      </UserProvider>
      <Analytics />
      <SpeedInsights />
    </>
  );
}

export default App;
