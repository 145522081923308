import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Box } from '@mui/material';

const TradeModal = ({ trades, isOpen, onClose }) => {
  const [timeLeft, setTimeLeft] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Convert UTC to local time
  const convertToLocalTime = (utcString) => {
    const utcDate = new Date(utcString);
    return utcDate.toLocaleString(); // Converts to the user's local timezone
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedTimes = trades.reduce((acc, trade) => {
        if (!trade.sent_at) {
          acc[trade.id] = "N/A"; // Display 'N/A' if sent_at is missing
          return acc;
        }
  
        const sentAtUtc = new Date(trade.sent_at + "Z"); // Ensure correct UTC parsing
        const expiresAt = sentAtUtc.getTime() + 5 * 60 * 1000; // Add 5 minutes in milliseconds
        const remaining = Math.max(0, Math.floor((expiresAt - Date.now()) / 1000)); // Convert to seconds
  
        acc[trade.id] = remaining;
        return acc;
      }, {});
  
      setTimeLeft(updatedTimes);
    }, 1000);
  
    return () => clearInterval(interval);
  }, [trades]);
  

  useEffect(() => {
    if (trades.length > 0) {
      setIsLoading(false);
    }
  }, [trades]);

  useEffect(() => {
    trades.forEach((trade) => {
      console.log("Raw sent_at:", trade.sent_at);
      console.log("Parsed sent_at:", new Date(trade.sent_at));
      console.log("Local sent_at:", new Date(trade.sent_at).toLocaleString());
    });
  }, [trades]);
  

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Active Trades</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Items</TableCell>
                  <TableCell>Time Left</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trades.map((trade) => (
                  <TableRow key={trade.id}>
                    <TableCell>{trade.item_ids.length} items</TableCell>
                    <TableCell>
                      {timeLeft[trade.id] > 0
                        ? `${Math.floor(timeLeft[trade.id] / 60)}m ${timeLeft[trade.id] % 60}s`
                        : 'Expired'}
                    </TableCell>
                    <TableCell>
                      {trade.trade_failed ? '❌ Failed' : trade.successful ? '✅ Successful' : '⏳ Pending'}
                    </TableCell>
                    <TableCell>
                      <Box>
                        {trade.sent_at && <p><strong>Sent At:</strong> {convertToLocalTime(trade.sent_at)}</p>}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <Button onClick={onClose} sx={{ margin: 2 }}>Close</Button>
    </Dialog>
  );
};

export default TradeModal;
